import apiAxios from '../../libs/apiAxios'

export default {
  namespaced: true,

  // Data
  state: {
    data: []
  },

  // Get data
  getters: {
    get(state) {
      return state.data;
    }
  },

  // Change data synchronously
  mutations: {
    set(state, payload) {
        state.data = payload;
    },

    init(state) {
      state.data = [];
    }
  },

  // Async methods
  actions: {
    async retrieve(context, payload) {
      const response = await apiAxios.get('reservation');

      // Translates backend response into frontend readable
      const data = response.data.map(item => {
        const res = {};

        res.id = item.id;

        // item.date example: 2022-11-04T10:00:00+00:00
        res.date = '';
        res.time = '';
        if (item.date !== undefined && item.date !== '' && item.date !== null) {
          // Retrieve date
          const dateArr = item.date.split('T');
          res.date = dateArr[0];

          if (dateArr.length > 1) {
            // Retrieve time
            const timeArr = dateArr[1].split('+');
            // Remove seconds
            const timeArrNoSec = timeArr[0].split(':');
            res.time = timeArrNoSec[0] + ':' + timeArrNoSec[1];
          } else {
            res.time = null;
          }
        }

        if (item.doctor != null) {
          res.doctor_id = item.doctor.id;
          res.doctor = item.doctor.label;
        }

        if (item.exam != null) {
          res.exam_id = item.exam.id;
          // res.exam = item.exam.label + (item.exam.cur != '' && item.exam.cur !== null ? ' (' + item.exam.cur + ')': '');
          res.exam = item.exam.label;
          res.preparation = item.exam.preparation;
        }

        if (item.insurance != null) {
          res.insurance_id = item.insurance.id;
          res.insurance = item.insurance.label;
          res.insurance_type = item.insurance.type;
        }

        if (item.practice != null) {
          res.location_id = item.practice.id;
          res.location = item.practice.label;
        }

        res.nr = item.nr;
        res.prescriptionDate = item.prescription_date;

        res.price = item.price;
        res.type = (item.type).toLowerCase();
        res.slotId = item.slot_id;
        res.diagnosticHypothesis = item.diagnostic_hypothesis;

        res.payble = item.payble;
        res.paid = item.paid;
        res.paymentMessage = item.payment_message;

        res.changeble = item.changeble;
        res.changeMessage = item.change_message;

        res.deletable = item.deletable;
        res.cancellationMessage = item.cancellation_message;

        res.prescriptions = item.prescriptions;
        res.prescriptions.forEach((p) => {
          p.exams = p.callback_exams;
          delete p.callback_exams;
        });
        res.callback = item.callback;

        res.booking_state = item.booking_state;

        res.error_message = item.error_message;

        res.request_document = item.request_document;

        res.show_time = item.show_time;

        // TODO: waiting for real fields
        res.iuv_code = '123';
        res.iuv_fiscal_code = '01234567891';

        return res;
      });

      context.commit('set', data);
    },

    async delete(context, payload) {
      try {
        const response = await apiAxios.delete('reservation/'+payload.id);

        return {success: true};
      } catch(e) {
        return {success: false, errorMessage: e.response.data.message};
      }
    },

    async sendDocument(context, payload) {
      try {
        const response = await apiAxios.post('reservation-document', payload);

        return { success: true };
      } catch (e) {
        return { success: false, errorMessage: e.response.data.message };
      }
    }
  }
};
