import apiAxios from '../../libs/apiAxios'

export default {
  namespaced: true,

  // Data
  state: {
    type: {
      value: null,
      valid: null,
      label: null
    },

    nre: {
      value: null,
      valid: null
    },
    nrr: {
      value: null,
      valid: null
    },
    cf: {
      value: null,
      valid: null
    },
    prescriptionDate: {
      value: null,
      valid: null
    },

    noInsurance: {
      value: null,
      valid: null
    },
    /*
    directInsurance: {
      value: null,
      valid: null,
      label: null
    },
    indirectInsurance: {
      value: null,
      valid: null,
      label: null
    },
    */
    insurance: {
      value: null,
      valid: null,
      label: null
    },

    exam: {
      value: null,
      valid: null,
      label: null
    },
    doctor: {
      value: null,
      valid: null
    },

    locations: [],

    doctorExam: {
      value: null,
      valid: null
    },

    availabilityDetail: {
      date: null,
      time: null,
      slots: null,
      // type: null,
    },

    slot: {
      id: null,
    },

    diagnosticHypothesis: {
      value: null,
      valid: null
    },

    steps: [{
              number: '-',
              label: 'Scelta regime',
              status: "active",
              visibile: true
            },
            {
              number: '-',
              label: 'Scelta prestazione',
              status: "disabled",
              visibile: true
            },
            {
              number: '-',
              label: 'Scelta disponibilità',
              status: "disabled",
              visibile: true
            },
            {
              number: '-',
              label: 'Prenota',
              status: "disabled",
              visibile: true
            }],

    bookingIdChanging: null,

    lastSaved: {},

    paid: false,

    queryParams: null,
  },

  // Get data
  getters: {
    get(state) {
      return state;
    },

    getType(state) {
      return state.type;
    },

    getNre(state) {
      return state.nre;
    },
    getNrr(state) {
      return state.nrr;
    },
    getPrescriptionType(state) {
      if (state.nre.value !== null) return 'D';
      else if (state.nrr.value !== null) return 'R';
      else return null;
    },
    getCf(state) {
      return state.cf;
    },
    getPrescriptionDate(state) {
      return state.prescriptionDate;
    },

    getNoInsurance(state) {
      return state.noInsurance;
    },
    /*
    getDirectInsurance(state) {
      return state.directInsurance;
    },
    getIndirectInsurance(state) {
      return state.indirectInsurance;
    },
    */
    getInsurance(state) {
      // return state.directInsurance ?? state.indirectInsurance;
      return state.insurance;
    },

    getExam(state) {
      return state.exam;
    },
    getDoctor(state) {
      return state.doctor;
    },
    getLocations(state) {
      return state.locations;
    },

    getDoctorExam(state) {
      return state.doctorExam;
    },

    getAvailabilityDetail(state) {
      return state.availabilityDetail;
    },

    getSlot(state) {
      return state.slot;
    },

    getDiagnosticHypothesis(state) {
      return state.diagnosticHypothesis;
    },

    getBooking(state) {
      const booking = {};

      booking.exam = state.exam.label;
      // booking.type = state.availabilityDetail.type;

      booking.nr = state.nre.value ?? state.nrr.value;
      booking.prescriptionDate = state.prescriptionDate.value;

      booking.date = state.availabilityDetail.date;
      booking.time = state.availabilityDetail.time;

      booking.slotId = state.slot.id;

      booking.doctor = '-';
      booking.price = null;
      booking.type = null;
      if (state.availabilityDetail.slots != null) {
        let slot = (state.availabilityDetail.slots.filter((s) => s.id === state.slot.id));
        if (slot.length > 0) {
          slot = slot[0];
          booking.doctor = slot.doctor;
          booking.price = slot.price;
          booking.orgId = slot.orgId;
          booking.agendaId = slot.agendaId;
          booking.visitMotiveId = slot.visitMotiveId;
          booking.vmpId = slot.vmpId;
          booking.type = slot.type;
          booking.location = slot.practiceLabel;
        }
      }

      // booking.insurance = state.directInsurance.label ?? state.indirectInsurance.label;
      booking.insurance = state.insurance.label;
      booking.insuranceId = state.insurance.value;
      booking.insurance_type = '';

      booking.diagnosticHypothesis = state.diagnosticHypothesis.value ?? '';

      booking.payble = false;
      booking.paid = state.paid;
      booking.paymentMessage = '';

      booking.deletable = false;
      booking.cancellationMessage = '';

      booking.changeble = false;
      booking.changeMessage = '';

      booking.bookingIdChanging = state.bookingIdChanging;

      booking.preparation = '';

      return booking;
    },

    getSteps(state) {
      return state.steps.filter((step) => step.visibile);
    },

    getBookingIdChanging(state) {
      return state.bookingIdChanging;
    },

    getLastSaved(state) {
      return state.lastSaved;
    },

    getQueryParams(state) {
      return state.queryParams;
    },
  },

  // Change data synchronously
  mutations: {
    set(state, payload) {
      if(payload.data == 'type') {
        state.type.value = payload.value;
        state.type.valid = payload.valid;
      }

      else if(payload.data == 'nre') {
        state.nre.value = payload.value;
        state.nre.valid = payload.valid;
      }
      else if(payload.data == 'nrr') {
        state.nrr.value = payload.value;
        state.nrr.valid = payload.valid;
      }
      else if(payload.data == 'cf') {
        state.cf.value = payload.value;
        state.cf.valid = payload.valid;
      }
      else if(payload.data == 'prescriptionDate') {
        state.prescriptionDate.value = payload.value;
        state.prescriptionDate.valid = payload.valid;
      }

      else if(payload.data == 'noInsurance') {
        state.noInsurance.value = payload.value;
        state.noInsurance.valid = payload.valid;
      }
      /*
      else if(payload.data == 'directInsurance') {
        state.directInsurance.value = payload.value;
        state.directInsurance.valid = payload.valid;
        state.directInsurance.label = payload.label;
      }
      else if(payload.data == 'indirectInsurance') {
        state.indirectInsurance.value = payload.value;
        state.indirectInsurance.valid = payload.valid;
        state.indirectInsurance.label = payload.label;
      }
      */
      else if(payload.data == 'insurance') {
        state.insurance.value = payload.value;
        state.insurance.valid = payload.valid;
        state.insurance.label = payload.label;
      }

      else if(payload.data == 'exam') {
        state.exam.value = payload.value;
        state.exam.valid = payload.valid;
        state.exam.label = payload.label;
      }
      else if(payload.data == 'doctor') {
        state.doctor.value = payload.value;
        state.doctor.valid = payload.valid;
      }

      else if(payload.data == 'doctorExam') {
        state.doctorExam.value = payload.value;
        state.doctorExam.valid = payload.valid;
      }

      else if(payload.data == 'availabilityDetail') {
        state.availabilityDetail.date = payload.date;
        state.availabilityDetail.time = payload.time;
        state.availabilityDetail.slots = payload.slots;
        // state.availabilityDetail.type = payload.type;
      }

      else if(payload.data == 'slot') {
        state.slot.id = payload.id;
      }

      else if(payload.data == 'diagnosticHypothesis') {
        state.diagnosticHypothesis.value = payload.value;
        state.diagnosticHypothesis.valid = payload.valid;
      }

      else if(payload.data == 'steps') {
        state.steps[payload.id].status = payload.value;
      }

      else if(payload.data == 'bookingIdChanging') {
        state.bookingIdChanging = payload.value;
      }

      else if(payload.data === 'paid') {
        state.paid = payload.value;
      }
    },

    setLocations(state, locations) {
      state.locations = locations;
    },

    unsetLocation(state, location) {
      state.locations = state.locations.filter((obj) => obj.id !== location.id);
    },

    init(state) {
      state.type.value = null;
      state.type.valid = null;

      state.nre.value = null;
      state.nre.valid = null;
      state.nrr.value = null;
      state.nrr.valid = null;
      state.cf.value = null;
      state.cf.valid = null;
      state.prescriptionDate.value = null;
      state.prescriptionDate.valid = null;

      state.noInsurance.value = null;
      state.noInsurance.valid = null;
      /*
      state.directInsurance.value = null;
      state.directInsurance.valid = null;
      state.directInsurance.label = null;
      state.indirectInsurance.value = null;
      state.indirectInsurance.valid = null;
      state.indirectInsurance.label = null;
      */
      state.insurance.value = null;
      state.insurance.valid = null;
      state.insurance.label = null;

      state.exam.value = null;
      state.exam.valid = null;
      state.exam.label = null;
      state.doctor.value = null;
      state.doctor.valid = null;

      state.locations = [];

      state.doctorExam.value = null;
      state.doctorExam.valid = null;

      state.availabilityDetail.date = null;
      state.availabilityDetail.time = null;
      state.availabilityDetail.slots = null;
      // state.availabilityDetail.type = null;

      state.slot.id = null;

      state.diagnosticHypothesis.value = null;
      state.diagnosticHypothesis.valid = null;

      state.steps[0].status = 'active';
      state.steps[1].status = 'disabled';
      state.steps[2].status = 'disabled';
      state.steps[3].status = 'disabled';

      state.bookingIdChanging = null;

      state.lastSaved = {};
    },

    hideStep(state, payload) {
      state.steps[payload.id].visibile = false;
    },

    setLastSaved(state, payload) {
      state.lastSaved = payload;
    },

    setState(state, payload) {
      Object.assign(state, payload);
    },

    setQueryParams(state, payload) {
      state.queryParams = payload;
    }
  },

  // Async methods
  actions: {
    async saveReservation(context) {
      const booking = context.getters.getBooking;

      let insurance = null;
      if (booking.type === 'sol')
          insurance = booking.insuranceId;

      let reservation = {
        slotId: booking.slotId,
        date: booking.date,
        diagnosticHypothesis: booking.diagnosticHypothesis,
        orgId: booking.orgId,
        agendaId: booking.agendaId,
        visitMotiveId: booking.visitMotiveId,
        insuranceId: insurance,
        vmpId: booking.vmpId
      };

      try {
        let response;
        // Edit existing reservation
        if (booking.bookingIdChanging !== null) {
          response = await apiAxios.put('reservation/'+booking.bookingIdChanging, reservation);
        // Insert new reservation
        } else {
          response = await apiAxios.post('reservation', reservation);
        }

        // Translates backend response into frontend readable
        if(response.data !== undefined && response.data !== null) {
          const item = response.data;
          let res = {};

          try {
            res.id =  item.id;

            // item.date example: 2022-11-04T10:00:00+00:00
            res.date = '';
            res.time = '';
            if(item.date !== undefined && item.date !== '' && item.date !== null) {
              // Retrieve date
              const dateArr = item.date.split('T');
              res.date = dateArr[0];
              // Retrieve time
              const timeArr = dateArr[1].split('+');
              // Remove seconds
              const timeArrNoSec = timeArr[0].split(':');
              res.time = timeArrNoSec[0] + ':' + timeArrNoSec[1];
            }

            if (item.doctor != null) {
              res.doctor_id = item.doctor.id;
              res.doctor = item.doctor.label;
            }

            if (item.exam != null) {
              res.exam_id = item.exam.id;
              // res.exam = item.exam.label + (item.exam.cur != '' && item.exam.cur !== null ? ' (' + item.exam.cur + ')': '');
              res.exam = item.exam.label;
              res.preparation = item.exam.preparation;
            }

            if (item.insurance != null) {
              res.insurance_id = item.insurance.id;
              res.insurance = item.insurance.label;
              res.insurance_type = item.insurance.type;
            }

            if (item.practice != null) {
              res.location_id = item.practice.id;
              res.location = item.practice.label;
            }

            res.nr = item.nr;
            res.prescriptionDate = item.prescription_date;

            res.price = item.price;
            res.type = (item.type).toLowerCase();
            res.slotId = item.slot_id;
            res.diagnosticHypothesis = item.diagnostic_hypothesis;

            res.payble = item.payble;
            res.paid = item.paid;
            res.paymentMessage = item.payment_message;

            res.changeble = item.changeble;
            res.changeMessage = item.change_message;

            res.deletable = item.deletable;
            res.cancellationMessage = item.cancellation_message;

            res.orgHasDl = item.agenda.organization.has_dl;

            // TODO: waiting for real fields
            res.iuv_code = '987';
            res.iuv_fiscal_code = '01234567891';

            context.commit('setLastSaved', res);
          } catch(e) {
            console.log('Error translating response');
          }
        }

        return { success: true };
      } catch (e) {
        return { success: false, errorMessage: e.response.data.message, code: e.response.status };
      }
    },

    saveStateToLocalStorage({ state }) {
      localStorage.setItem('bookingState', JSON.stringify(state));
    },

    restoreStateFromLocalStorage({ commit }) {
      const savedState = localStorage.getItem('bookingState');
      if (savedState) {
        commit('setState', JSON.parse(savedState));
        localStorage.removeItem('bookingState');
      }
    },

    updateQueryParams({ commit }, payload) {
      commit('setQueryParams', payload);
    },
  },
};
